'use client';

import { ChevronLeftIcon } from '@radix-ui/react-icons';
import { usePathname, useRouter } from 'next/navigation';
import { FC } from 'react';
import { Button } from '../components/ui/button';
export const BackButton: FC = () => {
  const pathname = usePathname();
  const {
    back
  } = useRouter();
  return pathname === '/' || pathname?.startsWith('/results') ? null : <Button variant="link" className="text-base -ml-4" onClick={() => back()} type="button" data-sentry-element="Button" data-sentry-component="BackButton" data-sentry-source-file="back-button.tsx">
      <div className="flex items-center space-x-1">
        <ChevronLeftIcon data-sentry-element="ChevronLeftIcon" data-sentry-source-file="back-button.tsx" />
        <span className="block">Back</span>
      </div>
    </Button>;
};