'use client';

import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
type MetadataContextType = {
  printTitle?: string;
  setPrintTitle: (value: string) => void;
};
const MetadataContext = createContext<MetadataContextType>({} as MetadataContextType);
export const MetadataProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const [printTitle, setPrintTitle] = useState('');
  const value = {
    printTitle,
    setPrintTitle
  };
  return <MetadataContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="MetadataProvider" data-sentry-source-file="metadata-provider.tsx">{children}</MetadataContext.Provider>;
};
export const useMetadataContext = () => useContext(MetadataContext);
export const usePrintTitle = (value: string) => {
  const {
    setPrintTitle
  } = useMetadataContext();
  useEffect(() => {
    setPrintTitle(value);
  }, [value, setPrintTitle]);
};