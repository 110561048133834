'use client';

import { FC, memo, useMemo } from 'react';
import { NavItem } from './nav-item';
import { usePathname } from 'next/navigation';
import { cn } from '../../utils/cn';
import { Route } from './types';
interface NavLinksProps {
  routes: Route[];
  className?: string;
  activeClassName?: string;
  exact?: boolean;
}
export const NavLinks: FC<NavLinksProps> = memo(({
  routes,
  className,
  activeClassName,
  exact
}) => {
  const pathname = usePathname() ?? '/';
  const navItems = useMemo(() => routes.map((route, i) => {
    const isActive = Boolean(pathname !== '/' && (exact || route.exact ? pathname === route.path : route.path && pathname.startsWith(route.path))) || route.additionalMatchingPaths?.some(({
      path,
      exact
    }) => !exact && pathname.startsWith(path) || exact && pathname === path);
    return <NavItem key={`route-${i}`} route={route} className={cn(className, route.className)} activeClassName={activeClassName} isActive={isActive} />;
  }), [routes, pathname, className, activeClassName, exact]);
  return <>{navItems}</>;
});