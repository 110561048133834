'use client';

import { LiveBadge } from '@spikemark/ui/shell/badges/live-badges';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
const IGNORED_PATHS = ['results', 'tournaments', 'schedule', 'rankings', 'teams', 'players', 'articles'];
export const LiveTournamentsHeaderPrompt = () => {
  const pathname = usePathname();
  if (IGNORED_PATHS.some(path => pathname?.includes(path))) {
    return null;
  }
  return <div className="bg-blue-dark w-full flex items-center justify-center text-white p-2 space-x-4" data-sentry-component="LiveTournamentsHeaderPrompt" data-sentry-source-file="live-tournaments-header-prompt.tsx">
      <LiveBadge status="live" data-sentry-element="LiveBadge" data-sentry-source-file="live-tournaments-header-prompt.tsx" />
      <p className="text-base">
        Tournaments in progress.
        <Link href="/results/current" className="underline ml-2" data-sentry-element="Link" data-sentry-source-file="live-tournaments-header-prompt.tsx">
          View Live Scores
        </Link>
      </p>
    </div>;
};