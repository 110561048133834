'use client';

import classNames from 'classnames';
import { HomeIcon, ListOrderedIcon, LucideIcon, NewspaperIcon, ShieldHalf, TrophyIcon } from 'lucide-react';
import { usePathname } from 'next/navigation';
import { FC } from 'react';
import { BaseLink as Link } from './navigation/entity-links';
import { Route } from '../shell/navigation/types';
const nativeFooterNavigation: FooterLinkItem[] = [{
  path: '/',
  title: 'Home',
  icon: HomeIcon
}, {
  path: '/results',
  title: 'Tournaments',
  icon: TrophyIcon
}, {
  path: '/rankings',
  title: 'Rankings',
  icon: ListOrderedIcon
}, {
  path: '/teams',
  title: 'Teams & Players',
  icon: ShieldHalf
}, {
  path: '/news',
  title: 'News',
  icon: NewspaperIcon
}];
type FooterLinkItem = Route & {
  icon: LucideIcon;
};
const FooterLink: FC<FooterLinkItem> = ({
  path,
  title,
  icon: Icon
}) => {
  const pathname = usePathname() ?? '/';
  const active = path && pathname.includes(path);
  return <Link href={path ?? '/'} className={classNames('flex flex-col items-center text-base appearance-none hocus:appearance-none', active ? 'font-bold' : 'font-light')} data-sentry-element="Link" data-sentry-component="FooterLink" data-sentry-source-file="native-footer.tsx">
      <Icon className="text-white w-6 h-6 mb-1" data-sentry-element="Icon" data-sentry-source-file="native-footer.tsx" />
      {title}
    </Link>;
};
export const NativeFooter = () => {
  return <>
      <div className="h-28" />
      <footer className="fixed bottom-0 left-0 right-0 z-20 bg-primary text-lg text-white shadow-nav-dark-bg">
        <nav className="relative flex items-center w-full max-w-screen-2xl mx-auto" style={{
        paddingBottom: 'calc(env(safe-area-inset-bottom) - 20px)'
      }}>
          <ul role="menubar" aria-orientation="horizontal" className="flex flex-grow justify-between px-6 pt-5 pb-8">
            {nativeFooterNavigation.map(item => <FooterLink {...item} />)}
          </ul>
        </nav>
      </footer>
    </>;
};