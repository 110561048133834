import classNames from 'classnames';
import { BaseLink as Link } from '../../components/navigation/entity-links';
import { FC } from 'react';
import { Route } from './types';
import { Button } from '../../components/ui/button';
export const NavItem: FC<{
  route: Route;
  className?: string;
  activeClassName?: string;
  isActive?: boolean;
}> = props => {
  const {
    route,
    className,
    activeClassName,
    isActive
  } = props;
  return <Link href={route.path ?? '/'} className={classNames('block p-2 lg:px-3 xl:px-4 grid h-full', isActive ? activeClassName : className, {
    'pointer-events-none': !route.path
  })} data-sentry-element="Link" data-sentry-component="NavItem" data-sentry-source-file="nav-item.tsx">
      {route.variant === 'button' ? <Button className="my-0.5" variant={'primary'} size="xl">
          {route.title}
        </Button> : route.title}
    </Link>;
};