import type { FC } from 'react';
import Image from 'next/image';
import classnames from 'classnames';
import LogoAltWhite from '../assets/scoreboard_logo_alt_centered_white.png';
import LogoAlt from '../assets/scoreboard_logo_alt_centered.png';
import Logo from '../assets/scoreboard_logo.png';
type BrandVariant = 'x-small' | 'small' | 'large' | 'xx-small';
type BrandProps = {
  className?: string;
  variant?: BrandVariant;
  logo?: 'default' | 'centered' | 'alt-centered';
  style?: React.CSSProperties;
};
export const Brand: FC<BrandProps> = ({
  className,
  variant,
  logo = 'default',
  style
}) => {
  const dimensions = variant === 'x-small' || variant === 'xx-small' ? {} : variant === 'small' ? {
    height: 32,
    width: 155
  } : {
    height: 45,
    width: 140
  };
  return <Image alt="Scoreboard powered by Clippd" src={logo === 'alt-centered' ? LogoAltWhite : logo === 'centered' ? LogoAlt : Logo} {...dimensions} width={variant === 'small' ? 115 : 140} height={variant === 'small' ? 32 : 45} priority quality={100} className={classnames({
    'w-16 h-5': variant === 'xx-small',
    'h-5 w-auto': variant === 'x-small',
    'w-brand-sm h-brand-sm': variant === 'small',
    'w-brand-lg h-brand-lg': variant === 'large'
  }, className)} style={style} data-sentry-element="Image" data-sentry-component="Brand" data-sentry-source-file="brand.tsx" />;
};