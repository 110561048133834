import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';
type HeaderContainerProps = PropsWithChildren<{
  className?: string;
  innerClassName?: string;
  variant?: 'small' | 'large' | 'auto';
}>;
export const HeaderContainer: FC<HeaderContainerProps> = ({
  children,
  className = 'border-zinc-200 border-b bg-white drop-shadow-sm',
  innerClassName,
  variant = 'auto'
}) => <div className={classNames(className, 'shell-header top-0 left-0 right-0 z-50')} id="scoreboard-header" data-sentry-component="HeaderContainer" data-sentry-source-file="header-container.tsx">
    <header className={classNames('flex items-center box-content relative', {
    'px-4 lg:px-8 lg:h-header h-header-mobile space-x-0 lg:space-x-12': variant === 'auto',
    'px-4 h-header-mobile space-x-0': variant === 'small',
    'pl-6 pr-4 h-header space-x-12': variant === 'large'
  }, innerClassName)}>
      {children}
    </header>
  </div>;