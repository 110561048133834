import { cn } from '../../utils/cn';
import { PropsWithChildren } from 'react';
type BaseBadgeProps = PropsWithChildren<{
  icon: React.ReactNode;
  className?: string;
}>;
export const BaseBadge = ({
  icon,
  children,
  className
}: BaseBadgeProps) => {
  const classes = cn('rounded-full flex items-center gap-2 font-semibold text-xs px-2 w-min font-roboto text-nowrap leading-loose border border-transparent', className);
  return <div className={classes} data-sentry-component="BaseBadge" data-sentry-source-file="base-badge.tsx">
      <span className="inline-block -mx-1">{icon}</span>
      {children}
    </div>;
};