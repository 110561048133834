'use client';

import { FC, PropsWithChildren } from 'react';
import { IClientConfig, ClientConfigProvider, ClientCacheProvider } from '@spikemark/core';
import { GoogleAnalytics } from '@spikemark/ui/shell/google-analytics';
export const AppProvider: FC<PropsWithChildren<{
  clientConfig: IClientConfig;
}>> = ({
  children,
  clientConfig
}) => {
  return <ClientConfigProvider value={clientConfig} data-sentry-element="ClientConfigProvider" data-sentry-component="AppProvider" data-sentry-source-file="app-provider.tsx">
      <GoogleAnalytics data-sentry-element="GoogleAnalytics" data-sentry-source-file="app-provider.tsx" />
      <ClientCacheProvider data-sentry-element="ClientCacheProvider" data-sentry-source-file="app-provider.tsx">{children}</ClientCacheProvider>
    </ClientConfigProvider>;
};