import { FC } from 'react';
import { BreakpointSwitch } from '../components/breakpoint-switch';
import { HeaderDesktop } from './header-desktop';
import { HeaderMobile } from './header-mobile';
import { Route } from './navigation/types';
interface HeaderProps {
  topLevelNavigation: Route[];
  isCapacitor?: boolean;
}
export const Header: FC<HeaderProps> = ({
  topLevelNavigation,
  isCapacitor
}) => {
  return <BreakpointSwitch breakpoint="lg" fallback={<HeaderMobile topLevelNavigation={topLevelNavigation} isCapacitor={isCapacitor} />} data-sentry-element="BreakpointSwitch" data-sentry-component="Header" data-sentry-source-file="header.tsx">
      <HeaderDesktop topLevelNavigation={topLevelNavigation} data-sentry-element="HeaderDesktop" data-sentry-source-file="header.tsx" />
    </BreakpointSwitch>;
};