'use client';

import { Capacitor } from '@capacitor/core';
import { Header } from '@spikemark/ui/shell/header';
import { Route } from '@spikemark/ui/shell/navigation/types';
import { FC } from 'react';
export const AppHeaderContent: FC<{
  routes: Route[];
}> = ({
  routes
}) => {
  return <div className="print:hidden" data-sentry-component="AppHeaderContent" data-sentry-source-file="app-header-content.tsx">
      <Header topLevelNavigation={routes} isCapacitor={Capacitor.isNativePlatform()} data-sentry-element="Header" data-sentry-source-file="app-header-content.tsx" />
    </div>;
};